@import "button.css";
@import "dashboard-screen.css";
@import "date-picker.css";
@import "employees.css";
@import "error.css";
@import "groups.css";
@import "initial-flow.css";
@import "initiatives.css";
@import "login-screen.css";
@import "modal.css";
@import "navbar.css";
@import "notifications.css";
@import "paginate.css";
@import "select-dropdown.css";
@import "statistics.css";
@import "success.css";
@import "user-profile.css";

@font-face {
  font-family: "DMSans-Regular";
  src: local("DM_Sans-Regular"),
    url("../static/fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: local("DM_Sans-Bold"),
    url("../static/fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
}

/* Main elements */
body {
  background: #f6f6f6;
  font-family: "DMSans-Regular";
  color: #373737;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1180px;
  margin: 0 auto;
}

.section {
  padding: 30px 0;
}

.section-between {
  padding-bottom: 30px;
}

.section-title {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 20px;
}

.section-title span{
  font-family: "DMSans-Bold";
}

.section-white {
  background: #ffffff;
}

.gray-text {
  color: #8c8c8c;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none;
}

input:hover {
  border: 1px solid #373737 !important;
}

input:focus {
  outline: none !important;
  border: 1px solid #f4f4f4 !important;
  background: #f4f4f4 !important;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background: transparent;
  background-image: url('../static/icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 20px;
}

select:hover {
  border: 1px solid #373737 !important;
}

select:focus {
  outline: none !important;
  border: 1px solid #f4f4f4 !important;
  background: #f4f4f4 !important;
}

textarea:hover {
  border: 1px solid #373737 !important;
}

textarea:focus {
  outline: none !important;
  border: 1px solid #f4f4f4 !important;
  background: #f4f4f4 !important;
}

.input-error {
    border: 1px solid #ff1a1a !important;
}

.input-error:focus {
  border: 1px solid #ff1a1a !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

.label-error {
  color: #ff1a1a !important;
}


