.dropdown-header {
    margin-top: 15px;
    font-size: 14px;
}

.dropdown-list {
    padding: 5px 15px;
    margin: 0;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    position: relative;
    top: 22px;
    right: 4%;
    width: 102%;
}

.dropdown-header .arrow-icon {
    border: none;
    background: transparent;
    position: relative;
}

.list-item {
    font-size: 14px;
    list-style: none;
    margin-bottom: 8px;
    border-radius: 6px;
    padding: 5px;
}

.list-item:first-child {
    margin-top: 8px;
}

.list-item:hover {
    font-family: "DMSans-Bold";
    background: #FAFAFA;
    cursor: pointer;
}