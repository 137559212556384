/* Error screen */

.error-screen {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.error-div {
    background: #ffffff;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-div-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.error-div-text .title {
    font-family: "DMSans-Bold";
    font-size: 20px;
}

.error-div-text .label {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

/* Error bar */

.error {
    position: fixed;
    text-align: center;
    background-color: #ff1a1a;
    color: #ffffff;
    padding: 20px 0;
    width: 100%;
    z-index: 999;
}