/* Date picker default classes overwriting */

.popover-inner {
    background-color: #ffffff;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 15px;
}

td:hover {
    font-family: "DMSans-Bold";
}

.text-muted {
    color: #AEAEAE;
}

.text-muted:hover {
    font-family: "DMSans-Regular";
}
