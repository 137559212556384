/* Navbar */

.navbar {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.08);
    padding: 0 24px;
    gap: 60px;
    position: relative;
}

.navbar .logo {
    flex: 2;
    padding: 12px 24px;
}

.navbar a {
    text-decoration: none;
}

.navbar .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 60px;
    padding: 16px 24px;
}

.navbar .links .link {
    font-size: 14px;
    color: #373737;
    font-weight: 500;
}

.navbar .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    cursor: pointer;
}

.navbar .language {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    position: relative;
}

.navbar-avatar {
    display: inline-block;
    border: 1px solid #01c158;
    margin: 0;
    padding: 6px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-avatar-inner {
    color: #01c158;
    font-size: 16px;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    margin: 0;
}

.navbar .name {
    font-size: 12px;
    color: #373737;
    margin: 0;
}

.navbar .email {
    font-size: 12px;
    color: #707070;
    margin: 0;
}

.navbar .button-toggle {
    background: transparent;
    border: none;
}

.navbar .button-toggle-actions-list {
    position: absolute;
    right: 19px;
    top: 20px;
}

.navbar .actions-list {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
    position: absolute;
    right: 5px;
    top: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    padding-right: 70px;
}

.navbar .actions-list .button-action-link, .navbar .language-actions-list .button-action-link {
    background: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    gap: 15px;
}

.navbar .language-actions-list .button-action-link:hover{
    color: #01C158;
}

.navbar .language-actions-list .active{
    color: #373737;
}

.navbar .language-actions-list {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
    position: absolute;
    left: 20px;
    top: -20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    padding-right: 70px;
}