/* Success bar */

.success {
    position: fixed;
    text-align: center;
    background-color: #01C158;
    color: #ffffff;
    padding: 20px 0;
    width: 100%;
    z-index: 999;
}