/* Dashboard */

.dashboard-actions {
    background-image: url('../static/images/background.png');
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 255px;
}

.button-add-employees {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #01C158;
    color: #01C158;
    padding: 12px 30px;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    font-size: 16px;
}

.add-employees-icon {
    height: 41px;
    width: 41px;
}


.button-add-initiative {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #01C158;
    border: 1px solid #01C158;
    color: #FFFFFF;
    padding: 12px 30px;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    font-size: 16px;
}

.add-initiative-icon {
    height: 41px;
    width: 41px;
}