/* Employees */

.add-initial-employees {
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.button-add-initial-employees {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #01C158;
    border: 20px solid #ffffff;
    color: #ffffff;
    padding: 12px 30px;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    font-size: 16px;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.10);
}

.add-employees-form {
    background: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin: 30px 0;
}

.add-employees-form .title-with-icon {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.add-employees-form .label {
    font-size: 20px;
}

.add-employees-form .form-file-upload {
    display: flex;
}

.add-employees-form .input-file-upload {
    display: none;
}

.add-employees-form .label-file-upload {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 50px;
    color: #373737;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px dashed #DADADA;
    padding-left: 16px;
    width: 78%;
    margin-right: 40px;
}

.add-employees-form .label-file-upload > p {
    margin-left: 10px;
}

.add-employees-form .label-file-upload .drag-active {
    background-color: #ffffff;
}

.add-employees-form .drag-file-element {
    position: absolute;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.upload-file-template {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
    font-size: 14px;
}

.add-employees-form .add-user-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.employee {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 12px 33px;
    margin-top: 15px;
    gap: 20px
}

.employee-avatar {
    display: inline-block;
    background-color: #01c158;
    margin: 10px;
    padding: 6px;
    border-radius: 50%;
}

.employee-avatar-inner {
    color: #fff;
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 30px;
    width: 30px;
}

.employee .name {
    font-weight: 500;
    width: 35%;
}

.employee .email {
    color: #707070;
    width: 45%;
}

.button-employee-action {
    background-color: #ffffff;
    color: #707070;
    border: 1px solid #707070;
    border-radius: 30px;
    padding: 6px 14px;
}

.button-employee-remove {
    background-color: #ffffff;
    border: none;
}

.employee-list-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 40px;
}

.employee-input-search {
    display: flex;
    align-items: center;
}

.employee-input-search .input-search {
    width: 270px;
    height: 25px;
    padding: 10px 0 10px 40px;
    border: 1px solid #d9d9d9;
    border-radius: 30px;
    display: block;
    position: relative;
    background-color: #ffffff;
}

.employee-input-search .input-search:focus {
    background: #ffffff !important;
}

.magnifier-icon {
    position: relative;
    left: 35px;
    z-index: 1;
}

.employee-input-search .button-clear-input {
    position: relative;
    background-color: transparent;
    border: none;
    right: 36px;
    top: 2px;
    z-index: 1;
}

.employee-select-count {
    display: flex;
    align-items: center;
    gap: 20px;
}

.employee-select-count .select-employees-number {
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #dadada;
    width: 60px;
    height: 40px;
    padding: 0px 0px;
}

.employee-select-count .dropdown-header {
    margin-top: 11px;
    padding: 0px 5px;
}

.employee-select-count .dropdown-list {
    width: 66%;
}

.employee-select-count .select-employees-number:hover {
    border: 1px solid #373737 !important;
}

.employee-select-count .select-employees-number:focus {
    outline: none !important;
    border: 1px solid #f4f4f4 !important;
    background: #f4f4f4 !important;
}

.employee-select-count .select-employees-number .arrow-icon {
    border: none;
    background: transparent;
    position: relative;
    left: 35px;
    width: 10px;
}