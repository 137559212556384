@font-face {
  font-family: 'WorkSans-Regular';
  src: local('Work_Sans-Regular'),
    url('../static/fonts/Work_Sans/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: local('Work_Sans-Medium'),
    url('../static/fonts/Work_Sans/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: local('Work_Sans-SemiBold'),
    url('../static/fonts/Work_Sans/WorkSans-SemiBold.ttf') format('truetype');
}

/* Modal */

.dark-background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 960px;
  background: #ffffff;
  padding: 5px 30px;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  font-size: 20px;
}

.modal-header button {
  background: none;
  border: none;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  padding: 20px;
}

.button-modal-cancel {
  margin-right: 15px;
  font-size: 14px;
}

.confirm-modal {
  height: 350px;
  width: 486px;
  text-align: center;
}

.confirm-modal-header {
  justify-content: flex-end;
  padding-top: 25px;
}

.confirm-modal .label {
  margin-top: 30px;
}

.delete-employee-icon {
  margin-top: -20px;
}

.confirm-modal-footer {
  justify-content: space-between;
  padding-top: 30px;
}

.confirm-modal-footer > button {
  width: 130px;
}

.preview-modal {
  width: 900px;
  padding: 30px;
}

.preview-modal-header {
  justify-content: flex-end;
  padding-top: -10px;
}

.preview-modal-body {
  display: flex;
  justify-content: space-between;
  padding: 0px 50px 10px;
}

.indicators {
  flex: 1;
  font-size: 12px;
}

.indicators .title {
  font-family: 'DMSans-Bold';
  font-size: 24px;
}

.indicators .editable {
  font-family: 'DMSans-Bold';
  background: #f4f4f4;
  padding: 5px;
  width: 120px;
  text-align: center;
}

.indicators .data {
  padding: 5px;
  text-align: center;
  border: 1px solid #dadada;
}

.indicators .photo {
  width: 120px;
  position: absolute;
  top: 230px;
}

.preview-bar-photo {
  position: absolute;
  top: 255px;
  left: 208px;
  height: 1px;
  background: #f4f4f4;
  width: 580px;
  z-index: 9;
}

.preview-bar-photo:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 10px;
  width: 10px;
  background: #f4f4f4;
  content: '';
  border-radius: 50px;
}

.indicators .name {
  width: 120px;
  position: absolute;
  top: 271px;
}

.preview-bar-name {
  position: absolute;
  top: 295px;
  left: 200px;
  height: 1px;
  background: #f4f4f4;
  width: 420px;
  z-index: 9;
}

.preview-bar-name:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 10px;
  width: 10px;
  background: #f4f4f4;
  content: '';
  border-radius: 50px;
}

.indicators .progress {
  width: 190px;
  position: absolute;
  top: 393px;
}

.preview-bar-progress {
  position: absolute;
  top: 418px;
  left: 282px;
  height: 1px;
  background: #dadada;
  width: 338px;
}

.preview-bar-progress:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 8px;
  width: 8px;
  background: #ffffff;
  border: 1px solid #dadada;
  content: '';
  border-radius: 50px;
}

.indicators .users {
  width: 120px;
  position: absolute;
  top: 362px;
}

.preview-bar-users {
  position: absolute;
  top: 388px;
  left: 212px;
  height: 1px;
  background: #dadada;
  width: 530px;
}

.preview-bar-users:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 8px;
  width: 8px;
  background: #ffffff;
  border: 1px solid #dadada;
  content: '';
  border-radius: 50px;
}

.indicators .goal {
  width: 120px;
  position: absolute;
  top: 452px;
}

.preview-bar-goal {
  position: absolute;
  top: 476px;
  left: 210px;
  height: 1px;
  background: #f4f4f4;
  width: 408px;
}

.preview-bar-goal:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 10px;
  width: 10px;
  background: #f4f4f4;
  content: '';
  border-radius: 50px;
}

.indicators .description {
  width: 120px;
  position: absolute;
  top: 521px;
}

.preview-bar-description {
  position: absolute;
  top: 545px;
  left: 210px;
  height: 1px;
  background: #f4f4f4;
  width: 408px;
}

.preview-bar-description:after {
  position: absolute;
  top: -5px;
  right: 0;
  height: 10px;
  width: 10px;
  background: #f4f4f4;
  content: '';
  border-radius: 50px;
}

.indicators .info {
  position: absolute;
  bottom: 30px;
}

.mobile-app-preview {
  flex: 1;
  height: 600px;
}

.mobile-app-preview-image {
  height: 600px;
  margin-left: 100px;
}

.mobile-app-back-button {
  position: absolute;
  top: 150px;
  right: 320px;
  z-index: 9;
  height: 20px;
}

.mobile-app-preview .photo {
  position: absolute;
  top: 127px;
  right: 96px;
  width: 271px;
  text-align: center;
}

.mobile-app-preview .photo-placeholder {
  position: absolute;
  top: 127px;
  right: 96px;
  width: 270px;
  height: 200px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(50, 50, 50, 0.15));
}

.mobile-app-preview .title {
  font-family: 'WorkSans-SemiBold';
  position: absolute;
  top: 255px;
  right: 93px;
  width: 250px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.mobile-app-preview .progressbar-container {
  position: absolute;
  top: 405px;
  right: 122px;
  width: 220px;
  display: flex;
  justify-content: flex-start;
  height: 0px;
  background-color: #dadada;
  border: 1px solid #dadada;
  border-radius: 16px;
  margin: 50;
}

.mobile-app-preview .progressbar-filler {
  height: 5px;
  background-color: #01c158;
  text-align: right;
  border-radius: 16px;
  position: relative;
  top: -3px;
  left: -1px;
}

.mobile-app-preview .progress-data {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 417px;
  right: 124px;
  width: 220px;
}

.mobile-app-preview .label-progress {
  color: #373737;
  font-family: 'WorkSans-Medium';
  font-size: 9px;
}

.mobile-app-preview .label-month {
  color: #707070;
  font-family: 'WorkSans-Medium';
  font-size: 9px;
}

.mobile-app-preview .initiative-data {
  position: absolute;
  top: 350px;
  right: 96px;
  width: 271px;
  display: flex;
}

.mobile-app-preview .initiative-data .initiative-type {
  flex: 1;
  margin-left: 25px;
}

.mobile-app-preview .initiative-data .initiative-type .action {
  font-family: 'WorkSans-Regular';
  font-size: 9px;
  color: #7d8494;
  margin: 0;
  padding: 0;
}

.mobile-app-preview .initiative-data .initiative-type .type {
  font-size: 12px;
  font-family: 'WorkSans-SemiBold';
  padding: 0;
  margin-top: 10px;
}

.mobile-app-preview .initiative-data .users {
  flex: 1;
}

.mobile-app-preview .initiative-data .users .label {
  font-family: 'WorkSans-Regular';
  font-size: 9px;
  color: #7d8494;
  margin: 0;
  padding: 0;
}

.mobile-app-preview .initiative-data .users .count {
  font-size: 12px;
  font-family: 'WorkSans-SemiBold';
  padding: 0;
  margin-top: 10px;
}

.mobile-app-preview .goal-title {
  font-family: 'WorkSans-SemiBold';
  position: absolute;
  top: 445px;
  right: 107px;
  width: 235px;
  font-size: 12px;
  text-align: left;
  color: #373737;
}

.mobile-app-preview .goal {
  font-family: 'WorkSans-Regular';
  position: absolute;
  top: 480px;
  right: 107px;
  width: 235px;
  max-height: 32px;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
  color: #707070;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-app-preview .description-title {
  font-family: 'WorkSans-SemiBold';
  position: absolute;
  top: 512px;
  right: 107px;
  width: 235px;
  font-size: 12px;
  text-align: left;
  color: #373737;
}

.mobile-app-preview .description {
  font-family: 'WorkSans-Regular';
  color: #707070;
  position: relative;
  bottom: 115px;
  left: 140px;
  width: 225px;
  max-height: 30px;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
