/* Initiatives */

.add-initial-initiative {
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.button-add-initial-initiative {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #01C158;
    border: 20px solid #ffffff;
    color: #ffffff;
    padding: 12px 30px;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    font-size: 16px;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.10);
}

.create-initative-button-label {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
}

.initiative {
    background-color: #ffffff;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 12px 33px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
}

.initiative>* {
    width: 100px;
}

.initiative {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.initiative .title {
    text-align: left;
    font-size: 16px;
    font-family: "DMSans-Bold";
    width: 25%;
}

.initiative label {
    color: #8c8c8c;
}

.initiative .progressbar-container {
    width: 10%;
    display: flex;
    justify-content: flex-start;
    height: 5px;
    background-color: #f5f5f5;
    border: 1px solid #e6e6e6;
    border-radius: 50px;
    margin: 50;
}

.initiative .progressbar-filler {
    height: 100%;
    background-color: #01C158;
    border-radius: 50px;
    text-align: right;
}

.initiative .button-statistics {
    color: #707070;
    background: #ffffff;
    padding: 6px 16px;
    border-radius: 60px;
    border: 1px solid #707070;
    width: 95px;
}

.initiative .button-edit {
    border: none;
    background: #ffffff;
    margin-top: 5px;
}

.initiative .button-action {
    border-radius: 30px;
    padding: 6px 16px;
    font-family: "DMSans-Bold";
    min-width: 88px;
}

.initiative .button-launch {
    border: none;
    background: #809CFF;
    color: #ffffff;
}

.initiative .button-finish {
    border: 1px solid #DA2F0A;
    background: #ffffff;
    color: #DA2F0A;
}

.initiative .button-launch-again {
    border: 1px solid #707070;
    background: #ffffff;
    color: #707070;
}

.initiative .historical {
   color: #707070;
}

.sorter {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 12px;
    color: #707070;
    margin-bottom: 30px;
}

.sorter button {
    border: 1px solid #707070;
    background: transparent;
    color: #707070;
    border-radius: 50px;
    font-size: 12px;
    padding: 6px 16px;
}

.sorter button.active {
    background: #dfdfdf;
}

.initiative-title-with-icon {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.initiative-title-with-icon .title {
    font-size: 24px;
    font-family: "DMSans-Bold";
    margin-bottom: 0;
    margin-top: 10px;
}

.initiative-title-with-icon .label {
    font-size: 16px;
    margin-top: 10px;
}

.create-initative-form-label {
    font-size: 16px;
    margin: 0;
}

.create-initative-form {
    display: flex;
    flex-direction: row;
}

.create-initative-form form {
    display: flex;
    flex-direction: row;
    gap: 35px;
    width: 100%;
}

.create-initative-form form .create-initative-form-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.create-initative-form .form-group {
    display: flex;
    flex-direction: column;
}

.create-initative-form .form-group-edit {
    margin-bottom: 0px;
}

.create-initative-form .form-group label {
    color: #707070;
    font-size: 12px;
    margin-bottom: 15px;
    font-weight: 500;
}

.create-initative-form .form-group-row {
    display: flex;
    flex-direction: row;
    gap: 35px;
}

.create-initative-form .form-group-row .form-group {
    width: 50%;
}

.create-initative-form .form-group .form-input {
    font-family: "DMSans-Regular";
    border: 0;
    border-radius: 50px;
    color: #373737;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 16px 40px 16px 24px;
    height: 16px;
}

.create-initative-form .form-group .input-edit-icon {
    border: none;
    background: transparent;
    position: relative;
    width: 26px;
    left: 93%;
    bottom: 39px;
}

.create-initative-form .form-group .input-edit-date-icon {
    border: none;
    background: transparent;
    position: relative;
    width: 26px;
    left: 80%;
    bottom: 39px;
}

.create-initative-form .form-group .form-select-month {
    font-family: "DMSans-Regular";
    font-size: 14px;
    border: 0;
    border-radius: 50px;
    color: #373737;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 0 50px 0 0;
    height: 50px;
    margin-bottom: 23px;
    z-index: 10;
}

.create-initative-form .form-group .form-select-month:hover {
    border: 1px solid #373737 !important;
}

.create-initative-form .form-group .form-select-month:focus {
    outline: none !important;
    border: 1px solid #f4f4f4 !important;
    background: #f4f4f4 !important;
}

.create-initative-form .dropdown-header {
    margin-top: 5px;
}

.create-initative-form .dropdown-list {
    width: 108%;
    left: 2px;
}

.create-initative-form .dropdown-list .select-year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 14px;
}

.create-initative-form .dropdown-list .select-year .label {
    font-family: "DMSans-Bold";
}

.button-select-year {
    display: flex;
    border: none;
    background: transparent;
}

.create-initative-form .dropdown-list .list-item-disabled {
    font-size: 14px;
    list-style: none;
    margin-bottom: 8px;
    border-radius: 6px;
    padding: 5px;
    color: #DADADA;
    pointer-events: none;
}

.create-initative-form .dropdown-list .list-item-disabled:hover {
    font-family: "DMSans-Regular";
    background: #FFFFFF;
    cursor: default;
}

.create-initative-form .form-group .form-select-month .calendar-icon {
    border: none;
    background: transparent;
    position: relative;
    left: 240px;
    top: 7px;
}

.form-select-range {
    display: flex;
    flex-direction: row;
    gap: 35px;
    margin-bottom: 25px;
}

.create-initative-form .form-group .form-textarea-description {
    font-family: "DMSans-Regular";
    line-height: 22px;
    resize: none;
    border: 0;
    border-radius: 12px;
    color: #373737;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 16px 24px;
    height: 190px;
}

.create-initative-form .form-group .form-textarea-description::-webkit-scrollbar {
    display: none;
}

.create-initative-form .form-group .form-textarea-goal {
    font-family: "DMSans-Regular";
    line-height: 22px;
    resize: none;
    border: 0;
    border-radius: 12px;
    color: #373737;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 16px 24px;
    height: 150px;
}

.create-initative-form .form-group .form-textarea-goal::-webkit-scrollbar {
    display: none;
}

.create-initative-form .create-initative-form-column .add-photo-form {
    height: 223px;
    display: flex;
    flex-direction: row;
    border: 1px solid #DADADA;
    border-radius: 12px;
}

.create-initative-form .create-initative-form-column .add-photo-form .add-photo-form-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    margin: 20px;
}

.create-initative-form .create-initative-form-column .add-photo-form .add-photo-form-column .description {
    color: #707070;
    font-size: 12px;
}

.create-initative-form .create-initative-form-column .form-file-upload {
    height: 100px;
    width: 100%;
    max-width: 100%;
    text-align: center;
}

.create-initative-form .create-initative-form-column .form-file-upload .input-file-upload {
    display: none;
}

.create-initative-form .create-initative-form-column .form-file-upload .label-file-upload {
    height: 180px;
    width: 240px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    color: #8d8d8d;
    background-color: #ffffff;
    border: 1px dashed #AEAEAE;
}

.create-initative-form .create-initative-form-column .form-file-upload .label-file-upload .drag-active {
    background-color: #ffffff;
}

.create-initative-form .create-initative-form-column .form-file-upload .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.initiative-photo {
    width: 240px;
    z-index: 0;
}

.button-delete-photo {
    border: none;
    background: transparent;
    position: relative;
    bottom: 38px;
    left: 95px;
    z-index: 9;
}

.button-upload-photo {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    color: #AEAEAE;
    border: 1px solid #AEAEAE;
    background: #ffffff;
    border-radius: 50px;
    padding: 12px 24px;
    margin: 0 auto;
}

.button-preview-modal {
    width: 250px;
    margin-top: 54px;
    align-self: end;
}

.button-submit-initiative {
    float: right;
}

.edit-initiative-title {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    font-size: 22px;
    font-weight: 500;
    padding: 16px 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin-top: 50px;
    margin-bottom: 25px;
}

.edit-initiative-action-bar {
    display: flex;
    background: #ffffff;
    gap: 20px;
    padding: 24px 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
}

.button-edit-initiative-action-bar {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #373737;
    background: #ffffff;
    padding: 8px 18px;
    border-radius: 60px;
    border: 1px solid #373737;
}

.edit-initiative-details {
    display: flex;
    gap: 20px;
    margin-bottom: 0px;
}

.edit-initiative-details .progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    padding: 20px 40px;
    width: 50%;
}

.edit-initiative-details .progress-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.edit-initiative-details .progress-data .number {
    color: #01C158;
    font-size: 24px;
    font-family: "DMSans-Bold";
    margin-top: 18px;
}

.edit-initiative-details .progress .description {
    color: #707070;
    font-size: 14px;
    margin-top: 0;
    line-height: 25px;
}

.progressbar-container {
    height: 15px;
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #e6e6e6;
    border-radius: 50px;
    margin: 50;
}

.progressbar-filler {
    height: 100%;
    background-color: #01C158;
    border-radius: 50px;
    text-align: right;
}

.edit-initiative-details .summary {
    display: flex;
    gap: 30px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    padding: 40px;
    width: 50%;
    line-height: 20px;
}

.edit-initiative-details .summary .summary-labels {
    width: 55%;
    font-size: 14px;
    color: #707070;
}

.edit-initiative-details .summary .summary-data {
    width: 45%;
    font-size: 14px;
    font-family: "DMSans-Bold";
}

.edit-initiative-actions {
    display: flex;
    gap: 50px;
    justify-content: flex-end;
}

.success {
    float: right;
    margin-top: 10px;
}

.button-delete-initiative {
    border: 1px solid #ff1a1a;
    background: #ffffff;
    color: #ff1a1a;
    padding: 16px 32px;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    font-size: 14px;
}