button {
  font-family: 'DMSans-Regular';
  cursor: pointer;
  font-size: 14px;
}

.button-green {
  background: #01c158;
  padding: 16px 30px;
  border-radius: 60px;
  border: 1px solid #01c158;
  font-family: 'DMSans-Bold';
  font-size: 14px;
  color: #ffffff;
}

.button-green:disabled {
  background: #bebdbd;
  border-color: #bebdbd;
}

.button-white-green {
  background: #ffffff;
  padding: 16px 30px;
  border-radius: 60px;
  border: 1px solid #01c158;
  font-family: 'DMSans-Bold';
  font-size: 14px;
  color: #01c158;
}

.button-violet {
  background: #809cff;
  padding: 16px 32px;
  border-radius: 60px;
  border: 0;
  font-family: 'DMSans-Bold';
  font-size: 14px;
  color: #ffffff;
}

.button-violet-white {
  color: #809cff;
  padding: 16px 32px;
  border-radius: 60px;
  border: 1px solid #809cff;
  font-family: 'DMSans-Bold';
  font-size: 16px;
  background: #ffffff;
}

.button-with-icon {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 26px 12px 12px;
}
