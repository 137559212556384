/* Notifications */

.notifications-title-with-icon {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.initiative-title-with-icon .label {
    font-size: 16px;
    margin-top: 10px;
}

.create-notification-form {
    background: #ffffff;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
}

.create-notification-form form {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    border-radius: 12px;
    gap: 20px;
}

.create-notification-form form .create-notification-form-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
}

.create-notification-form form .create-notification-form-column .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.create-notification-form form .create-notification-form-column .form-group label {
    color: #707070;
    font-size: 12px;
    margin-bottom: 10px;
}

.create-notification-form .form-group-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-notification-form .form-input-title {
    padding: 15px 0 15px 15px;
    height: 18px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    color: #373737;
    font-size: 14px;
    border-radius: 50px;
    margin-bottom: 25px;
}

.create-notification-form .form-textarea-content {
    font-family: "DMSans-Regular";
    line-height: 22px;
    resize: none;
    height: 118px;
    padding: 15px 0 15px 15px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    color: #373737;
    font-size: 14px;
    border-radius: 12px;
}

.create-notification-form .form-textarea-content::-webkit-scrollbar {
    display: none;
}

.create-notification-form .form-select {
    height: 49px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    color: #373737;
    border-radius: 50px;
    padding-left: 10px;
    margin-bottom: 25px;
}

.create-notification-form .form-select:hover {
    border: 1px solid #373737 !important;
}

.create-notification-form .form-select:focus {
    outline: none !important;
    border: 1px solid #f4f4f4 !important;
    background: #f4f4f4 !important;
}

.create-notification-form .dropdown-list {
    width: 96%;
    right: 9px;
    z-index: 9;
    max-height: 400px;
    overflow: auto;
}

.create-notification-form .form-select .arrow-icon {
    border: none;
    background: transparent;
    position: relative;
    left: 502px;
    z-index: 1;
}

.button-submit-notification {
    padding: 16px 32px;
    border-radius: 60px;
    padding: 14px 40px;
    color: #ffffff;
    margin-top: 20px;
    float: right;
}