/* Statistics */
.back-button {
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.statistics-container {
    background: #ffffff;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin-bottom: 0px;
}

.statistics-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    margin-bottom: 30px;
}

.statistics-data .number {
    color: #01C158;
    font-size: 24px;
    font-family: "DMSans-Bold";
}

.statistics-data .km {
    color: #707070;
    font-size: 24px;
    font-family: "DMSans-Regular";
}

.statistics-container .form-group-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 35px;
}

.statistics-container .form-group-row .label {
    margin-right: 40px;
}

.statistics-container .form-group-row > div {
    position: relative;
}

.statistics-container .form-input {
    font-family: "DMSans-Regular";
    border: 0;
    border-radius: 50px;
    color: #373737;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 16px 24px;
    width: 150px;
}

.calendar-icon-statistics {
    position: absolute;
    right: 20px;
    bottom: 0;
    top: 0;
    margin: auto;
}

.statistics-elements {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.statistics-list {
    margin: 0;
    padding: 0;
}

.statistics-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.statistics-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.statistics-list li:nth-child(odd) {
    background-color: #F4F4F4;
}

.ranking {
    width: 100%;
    padding: 0 10px;
}

.ranking-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ranking-row .title {
    font-family: "DMSans-Bold";
}

.ranking-row .steps {
    margin-right: 14px;
}

.ranking-row .label-avg {
    margin-left: 330px;
    font-family: "DMSans-Bold";
}

.ranking-list {
    margin: 0;
    padding: 0;
    height: 240px;
}

.ranking-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 40px;
}

.ranking-link {
    cursor: pointer;
}

.ranking-list li:nth-child(odd) {
    background-color: #F4F4F4;
}

.ranking-list li .avatar {
    display: inline-block;
    margin-right: 16px;
    padding: 8px;
    border-radius: 50%;
}

.ranking-list li .avatar-inner {
    color: #373737;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 20px;
    width: 20px;
}

.ranking-list li .avatar-first-green {
    display: inline-block;
    background-color: #01C158;
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-second-green {
    display: inline-block;
    background-color: rgba(1, 193, 88, 0.7);
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-third-green {
    display: inline-block;
    background-color: rgba(1, 193, 88, 0.4);
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-first-violet {
    display: inline-block;
    background-color: #809CFF;
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-second-violet {
    display: inline-block;
    background-color: rgba(128, 156, 255, 0.7);
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-third-violet {
    display: inline-block;
    background-color: rgba(128, 156, 255, 0.4);
    margin-right: 15px;
    padding: 3px;
    border-radius: 50%;
}

.ranking-list li .avatar-inner-top {
    color: #fff;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 20px;
    width: 20px;
}

.ranking-list li .name {
    width: 80%;
}

.ranking-list li .top-name {
    width: 80%;
    font-family: "DMSans-Bold";
}

.ranking-list li .value {
    width: 15%;
    text-align: end;
}

.initiative-ranking li .name {
    width: 35%;
    text-align: start;
}

.initiative-ranking li .top-name {
    width: 35%;
    font-family: "DMSans-Bold";
    text-align: start;
}

.initiative-ranking li .value-avg {
    width: 15%;
    text-align: center;
    margin-left: 146px;
}

.initiative-ranking li .value-sum {
    width: 30%;
    text-align: end;
}

.chart {
    width: 100%;
    padding: 0 10px;
}

.chart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chart-title {
    font-family: "DMSans-Bold";
}