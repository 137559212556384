.pagination {
    margin-top: 30px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 30px;
    padding: 0;
}

.page-link {
    border: 1px solid #AEAEAE;
    border-radius: 50px;
    color: #AEAEAE;
    background: transparent;
    font-size: 12px;
    width: 16px;
    padding: 1px;
    text-align: center;
    cursor: pointer;
}

.page-link-green:hover {
    border: 1px solid #01C158;
    border-radius: 50px;
    color: #01C158;
    background: transparent;
    font-size: 12px;
    width: 16px;
}

.page-link .active-green {
    color: #01C158;
    font-weight: bold;
}

.page-link-violet:hover {
    border: 1px solid #809CFF;
    border-radius: 50px;
    color: #809CFF;
    background: transparent;
    font-size: 12px;
    width: 16px;
}

.page-link .active-violet {
    color: #809CFF;
    font-weight: bold;
}

.previous {
    border: 1px solid #AEAEAE;
    border-radius: 50px;
    color: #AEAEAE;
    background: transparent;
    font-size: 12px;
    width: 16px;
    padding: 1px;
    text-align: center;
    cursor: pointer;
}

.next {
    border: 1px solid #AEAEAE;
    border-radius: 50px;
    color: #AEAEAE;
    background: transparent;
    font-size: 12px;
    width: 16px;
    padding: 1px;
    text-align: center;
    cursor: pointer;
}

.disabled {
    border: 1px solid #AEAEAE;
    border-radius: 50px;
    color: #AEAEAE;
    background: transparent;
    font-size: 12px;
    width: 16px;
    padding: 1px;
    text-align: center;
    cursor: default;
}