/* Login screen */

.login-screen {
    background-image: url('../static/images/background.png');
    background-size: contain;
    background-repeat: repeat;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.login-form {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.16);
    width: 480px;
}

.login-form .header {
    font-family: "DMSans-Bold";
    font-size: 20px;
    margin: 0;
}

.login-form .label {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.login-form .form-group label {
    color: #707070;
    font-size: 14px;
}

.login-form .form-group .form-input {
    padding: 10px 0 10px 20px;
    color: #858585;
    border: 1px solid #d9d9d9;
    border-radius: 40px;
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 30px;
    width: 94%;
}

.login-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.login-statute-row {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #707070;
    font-size: 14px;
}

.login-statute-row input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    border: 1px solid #dadada;
    outline: none;
}

.login-statute-row input[type="checkbox"]:focus {
    background: #ffffff !important;
    border: 1px solid #dadada !important;
}

.login-statute-row input[type="checkbox"]:checked {
    background: #01C158 !important;
}


.login-statute-row .statute-link {
    text-decoration: none;
    color: #01C158;
}

.login-form .reset-password-link {
    color: #707070;
    font-size: 14px;
    text-decoration: none;
    margin: 20px 0;
    display: block;
}

.login-form .button-submit-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    background: #01C158;
    border: 1px solid #01C158;
    border-radius: 60px;
    color: #ffffff;
    font-family: "DMSans-Bold";
    padding: 20px 30px;
}

.login-form .button-submit-reset-password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    background: #01C158;
    border: 1px solid #01C158;
    border-radius: 60px;
    color: #ffffff;
    font-family: "DMSans-Bold";
    padding: 20px 30px;
    float: right;
}

.status-info {
    position: absolute;
    font-size: 10px;
}