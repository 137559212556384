body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* DATEPICKER + OVERRIDE INLINE */
.react-datepicker {
  border-radius: 16px;
  border: 0;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.06);
  font-family: 'DMSans-Regular';
}

.react-datepicker__header, .react-datepicker__day--keyboard-selected {
  background-color: #fff;
  border: 0;
}

.react-datepicker__day--keyboard-selected:hover {
  color: #f0f0f0;
}

.react-datepicker__header > div {
  justify-content: space-between !important;
}

.react-datepicker__header button {
  background-color: #fff;
  border: 0;
}

.react-datepicker__header select, .react-datepicker__header select:hover {
  border: 0 !important;
}

.react-datepicker__day-name, .react-datepicker__day--outside-month {
  color: #c6c6c7;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover {
  background-color: #03c158;
  border-radius: 50%;
}

.react-datepicker-wrapper {
  width: fit-content;
}