/* Initial flow stepper */

.initial-flow-stepper-section {
    background-image: url('../static/images/background.png');
    background-size: cover;
    padding: 50px 0;
}

.initial-flow-stepper {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    justify-content: space-around;
    bottom: 0;
    width: 100%;
    padding: 20px 0px;
    display: table;
}

.initial-flow-stepper>div {
    align-items: center;
    font-size: 12px;
    text-align: center;
    display: table-cell;
    position: relative;
}

.step-bar-left,
.step-bar-right {
    position: absolute;
    top: 54px;
    height: 1px;
}

.step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
}

.step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
}

.initial-flow-stepper-link {
    text-decoration: none;
}

.step-waiting .step-bar-right {
    background: #01C158;
}

.step-waiting .step-bar-left {
    background: #01C158;
}

.step-active .step-bar-right {
    background: #01C158;
}

.step-active .step-bar-left {
    background: linear-gradient(to right, #AEAEAE, #01C158);
}

.step-done .step-bar-right {
    background: #AEAEAE;
}

.step-done .step-bar-left {
    background: #AEAEAE;
}

.initial-flow-stepper .step-active .step-title {
    color: #01C158;
    font-family: "DMSans-Bold";
}

.initial-flow-stepper .step-done .step-title {
    color: #AEAEAE;
}

.initial-flow-stepper .step-waiting .step-title {
    color: #01C158;
}

.initial-flow-stepper .step-active .step-number {
    line-height: 11px;
    font-size: 13px;
    padding: 10px;
    font-family: "DMSans-Bold";
    background: #01C158;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    color: #ffffff;
    margin: 0 auto;
}

.initial-flow-stepper .complete-icon {
    width: 32px;
    height: 32px;
}

.initial-flow-stepper .step-waiting .step-number {
    line-height: 11px;
    font-size: 13px;
    padding: 10px;
    font-family: "DMSans-Bold";
    background: #ffffff;
    border: 1px solid #01C158;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    color: #01C158;
    margin: 0 auto;
}

.initial-flow-stepper .step-active .step-description {
    color: #01C158;
    font-family: "DMSans-Bold";
}

.initial-flow-stepper .step-done .step-description {
    color: #AEAEAE;
}

.initial-flow-stepper .step-waiting .step-description {
    color: #707070;
}

/* Initial flow footer */

.initial-flow-footer {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -4px 2px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 100px;
    z-index: 999;
}

.initial-flow-footer .button-go-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    background: #ffffff;
    border: 1px solid #01C158;
    border-radius: 60px;
    color: #01C158;
    font-family: "DMSans-Bold";
    font-size: 16px;
    padding: 20px 30px;
}

.initial-flow-footer .button-go-forward {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    background: #01C158;
    border: 1px solid #01C158;
    border-radius: 60px;
    color: #ffffff;
    font-family: "DMSans-Bold";
    font-size: 16px;
    padding: 20px 30px;
}

.initial-flow-confirm-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 200px;
}

.initial-flow-confirm-footer .button-save-initiative {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 60px;
    font-family: "DMSans-Bold";
    padding: 20px 30px;
    width: 200px;
}

.initial-flow-confirm-footer .button-launch-initiative {
    background: #01C158;
    border: 1px solid #01C158;
    border-radius: 60px;
    color: #ffffff;
    font-family: "DMSans-Bold";
    padding: 20px 30px;
    width: 200px;
}

.initial-flow-footer-spacer {
    height: 100px;
    width: 100%;
}