/* Groups */

.create-group-form-title {
    font-size: 20px;
    margin: 30px 0;
}

.create-group-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 40px 50px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin: 10px 0 50px;
}

.create-group-form .label {
    color: #373737;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.create-groups {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.create-groups .create-group-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.create-groups .create-group-form .title-with-icon {
    color: #373737;
    font-size: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.create-groups .create-group-form .label {
    color: #373737;
    font-size: 16px;
    margin-bottom: 40px;
}

.create-group-form .form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.create-group-form .form-group label {
    font-size: 14px;
    margin: 10px 0;
}

.create-group-shuffled-form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 50px 40px;
}

.create-group-shuffled-form .form-group-select-group-shuffled {
    width: 45%;
}

.create-group-shuffled-form .form-group-select-group-shuffled label {
    font-size: 12px;
}

.create-group-shuffled-form .select-group-shuffled {
    border-radius: 30px;
    border: 1px solid #dadada;
    height: 50px;
    padding: 0 20px;
    margin-top: 10px;
    width: 100%;
}

.create-group-shuffled-form .select-group-shuffled:hover {
    border: 1px solid #373737 !important;
}

.create-group-shuffled-form .select-group-shuffled:focus {
    outline: none !important;
    border: 1px solid #f4f4f4 !important;
    background: #f4f4f4 !important;
}

.create-group-shuffled-form .dropdown-list {
    width: 102%;
}

.create-group-shuffled-form .select-group-shuffled .arrow-icon {
    border: none;
    background: transparent;
    position: relative;
    left: 470px;
}

.create-group-shuffled-form .form-group-number {
    display: flex;
    flex-direction: column;
    width: 10%;
}

.create-group-shuffled-form .form-group-number label {
    font-size: 12px;
}

.create-group-shuffled-form .form-group-number input {
    border-radius: 30px;
    border: 1px solid #dadada;
    height: 50px;
    padding: 0 20px;
    margin-top: 10px;
    text-align: center;
    font-family: "DMSans-Regular";
}

.create-group-shuffled-form button {
    margin-top: 26px;
}

.groups .label {
    margin-bottom: 35px;
    line-height: 30px;
}

.create-group-modal-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    margin: 20px 0 40px;
}

.create-group-modal-form .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
}

.create-group-modal-form .form-group label {
    margin-right: 10px;
}

.create-group-modal-form .form-group .form-input {
    height: 25px;
    width: 200px;
    font-size: 16px;
    font-family: "DMSans-Bold";
    padding: 10px 0 10px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 30px;
    display: block;
}

.create-group-modal-form .form-group .form-input-autocomplete {
    width: 350px;
    height: 25px;
    padding: 10px 0 10px 40px;
    border: 1px solid #d9d9d9;
    border-radius: 30px;
    display: block;
    position: relative;
}

.create-group-modal-form .form-group-autocomplete {
    position: relative;
}

.create-group-modal-form .form-group-autocomplete .input-search {
    display: flex;
}

.magnifier-icon {
    position: relative;
    left: 35px;
    z-index: 1;
}

.input-search .button-clear-input {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 16px;
    top: 16px;
    z-index: 1;
}

.clear-icon {
    z-index: 1;
}

.suggestions {
    background-color: #ffffff;
    border: none;
    border-radius: 12px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    margin-top: 0;
    max-height: 70vh;
    overflow-y: auto;
    padding: 5px;
    position: absolute;
    left: 18px;
    z-index: 999;
    width: 380px;
}

.suggestions li {
    padding: 7px 15px;
    border: none;
}

.suggestion-active,
.suggestions li:hover {
    cursor: pointer;
    font-family: "DMSans-Bold";
}

.no-suggestions {
    background-color: #ffffff;
    border: none;
    border-radius: 12px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    margin-top: 0;
    overflow-y: auto;
    padding: 5px;
    position: absolute;
    left: 18px;
    z-index: 999;
    width: 380px;
}

.no-suggestions li {
    padding: 7px 15px;
    border: none;
}

.group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    padding: 5px 30px;
    margin-bottom: 10px;
}

.group-empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #707070;
    background: rgba(0, 0, 0, 0.03);
    border: 1px dashed #AEAEAE;
    border-radius: 12px;
    padding: 5px 30px;
    margin-bottom: 10px;
}

.group-avatar {
    display: inline-block;
    background-color: #809CFF;
    margin: 10px;
    padding: 6px;
    border-radius: 50%;
}

.group-avatar-inner {
    color: #fff;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 20px;
    width: 20px;
}

.group .title {
    width: 25%;
    font-family: "DMSans-Bold";
}

.group .users {
    width: 25%;
}

.group-empty .title {
    width: 25%;
}

.group-empty .users {
    width: 25%;
}

.button-edit-group {
    padding: 8px 16px;
    font-size: 14px;
    margin-left: 20px;
    font-family: "DMSans-Bold";
}

.button-add-group {
    padding: 8px 16px;
    font-size: 14px;
}

.group-empty .button-edit-group {
    padding: 8px 16px;
    font-size: 14px;
    margin-left: 0px;
}

.group .button-notification {
    color: #707070;
    background: #ffffff;
    padding: 6px 12px;
    border-radius: 60px;
    border: 1px solid #707070;
}

.group .button-statistics {
    color: #707070;
    background: #ffffff;
    padding: 6px 12px;
    border-radius: 60px;
    border: 1px solid #707070;
}

.group-add-empty {
    display: flex;
    align-items: center;
    color: #707070;
    background: rgba(0, 0, 0, 0.03);
    border: 1px dashed #AEAEAE;
    border-radius: 12px;
    padding: 5px 30px;
    margin-bottom: 10px;
}

.group-add-empty .group-avatar {
    margin-right: 35px;
}

.group-add-empty .title {
    width: 27.5%;
}

.group-add-empty .users {
    width: 43.5%;
}

.group-add-empty .button-edit-group {
    margin-right: 32px;
}

/* Users */

.add-user-form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.add-user-form .form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25%;
}

.add-user-form .form-group label {
    font-size: 12px;
    margin-bottom: 10px;
}

.add-user-form .form-group .form-input {
    border: 0;
    border-radius: 50px;
    color: #373737;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #DADADA;
    padding: 16px 24px;
    width: 78%;
    margin-right: 40px;
}

.group-users {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 12px;
    height: 170px;
    margin-bottom: 10px;
}

.group-searched-users {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 10px;
    height: 370px;
}

.group-users .label {
    margin: 5px 0;
    padding: 0;
    font-size: 12px;
}

.group-users .label span {
    background: #809CFF;
    color: #ffffff;
    font-family: "DMSans-Bold";
    padding: 2px 4px;
    border-radius: 4px;
}

.group-searched-users .label {
    margin: 5px 0;
    padding: 0;
    font-size: 12px;
}

.group-searched-users .label span {
    background: #809CFF;
    color: #ffffff;
    font-family: "DMSans-Bold";
    padding: 2px 4px;
    border-radius: 4px;
}

.groups-users-list {
    height: 155px;
    overflow-y: auto;
}

.groups-searched-users-list {
    height: 340px;
    overflow-y: auto;
}

.group-user {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    padding: 0px 30px;
    margin-top: 5px;
    gap: 20px;
    height: 44px;
}

.group-user .icon-name {
    display: flex;
    font-family: "DMSans-Bold";
    flex: 1;
}

.group-user .email {
    color: #8c8c8c;
    flex: 1;
}

.button-group-user-remove {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #AEAEAE;
    background-color: #ffffff;
    border: none;
    width: 130px;
}

.button-group-user-add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #809CFF;
    background-color: #ffffff;
    border: none;
    width: 140px;
}

.other-group-info {
    color: #373737;
    font-size: 14px;
    text-align: center;
    width: 140px;
}

.user-icon {
    margin-right: 30px;
}

.button-group-creation-remove {
    background-color: transparent;
    border: none;
}

.button-group-creation-remove-white {
    background-color: #ffffff;
    border: none;
}

.button-group-edit-remove {
    background-color: #ffffff;
    border: none;
}


@media screen and (min-width: 1800px) {
    .group-users {
       height: 340px;
    }

    .groups-users-list {
        height: 320px;
        overflow-y: auto;
    }

    .group-searched-users {
        height: 710px;
    }

    .groups-searched-users-list {
        height: 710px;
        overflow-y: auto;
    }
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    margin: 10px 0;
}

::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #DADADA;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
