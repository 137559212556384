/* User profile */

.user-profile-container {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    display: flex;
    gap: 50px;
    padding: 40px;
    line-height: 20px;
}

.user-profile-container .user-profile-labels {
    width: 50%;
    font-size: 14px;
    color: #707070;
}

.user-profile-container .user-profile-data {
    width: 50%;
    font-size: 14px;
    font-family: "DMSans-Bold";
}